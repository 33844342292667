import { ServiceFactory } from '@/services/ServiceFactory'
import moment from 'moment'
moment.locale('es')
const _billingService = ServiceFactory.get('BillingService')
export default {
  name: 'BillingComponent',
  data () {
    return {
      billingPeriod: null,
      promoCode: null,
      penalty: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      reservations: {},
      isLoading: false
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    month () {
      return moment(this.date).format('MMMM')
    }
  },
  watch: {
    date: {
      handler (newVal, oldVal) {
        this.showReservations(newVal)
      }
    }
  },
  mounted () {
    this.showReservations(this.date)
  },
  methods: {
    showReservations (date) {
      this.isLoading = true
      _billingService.showReservations(date).then((resp) => {
        if (resp.success) {
          this.reservations = resp.billing
          this.billingPeriod = resp.billingPeriod
          this.isLoading = false
          let p = Object.keys(resp.billing.promoCodes ? resp.billing.promoCodes : {})
          if (p.length > 0) {
            this.promoCode = p[0]
          } else {
            this.promoCode = null
          }
        }
      }).catch((err) => {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: err.msg
        })
        this.isLoading = false
      })
    }
  }
}
